import React from 'react';
import { Link } from 'react-router-dom';

export default class DashboardResources extends React.Component {
  constructor(props) {
    super(props);
  }

    onClickOfPsychIncubator = () => {
      window.location.href = "https://psychincubator.com/";
    }
    onClickOfStratPsychTx = () => {
      window.location.href = "https://stratpsychtx.com/";
    }
    onClickOfPsychTrends = () => {
      window.location.href = "https://psychtrends.godaddysites.com/";
    }

    render() {
      return (
        <div className="bg-white">
          <div className="container-fluid text-center text-info bg-white" id="your-resources">
            <div className="row">
              <div className="col">
                <div className="h3 py-5 bg-white text-dark text-bold">Here are some other resources included with your membership.</div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ul className="list-group dashboard-resources">
                  <li className="list-group-item" data-toggle="modal" data-target=".bd-example-modal-lg1">
                                    psychotherapedia <sup>&reg;</sup>
                  </li>
                  <li className="list-group-item" onClick={this.onClickOfPsychTrends}>
                                    PsychTrends <sup>&reg;</sup>
                  </li>
                  <li className="list-group-item" onClick={this.onClickOfPsychIncubator}> 
                                    PsychIncubator <sup>&reg;</sup>
                  </li>
                  <li className="list-group-item" data-toggle="modal" data-target="">
                    <Link to="/approaches" className="text-info d-block"> Approaches</Link>
                  </li>
                  <li className="list-group-item" data-toggle="modal" data-target=".bd-example-modal-lg4"> 
                                    StratPsychDx
                  </li>
                  <li className="list-group-item" onClick={this.onClickOfStratPsychTx}> 
                     StratPsychTx
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
}