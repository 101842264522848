import React from "react";
import { Link } from 'react-router-dom';
import './footer.scss';

export default class Footer extends React.Component {

  render() {
    return (
        <footer >
            <div className="footer-page-banner-wrapper">
                <div className="footer-banner-image" ></div>
                <div className="footer-banner-content">
                    <div className="footer-course-top-wrapper mt--40">
                    <div className="row p-4 text-white">
                            <div className="col-sm-4 text-left">
                            <h5 className="custom-footer-color">ABOUT STRATPSYCH <sup>&reg;</sup></h5> 
                            <p className="custom-footer-color">Welcome to StratPsych <sup>&reg;</sup> , the online learning center for mental and behavioral health practitioners.</p>
                            </div>
                            <div className="col-sm-4 text-left"> 
                            
                            <h5 className="custom-footer-color">CONTACT US</h5>
                                <p className="mb-2"> <a href=" https://www.strategicpsychotherapeutics.com" className="custom-footer-link break-word" target="_top"> <i className="fa fa-globe"></i>
                                    www.strategicpsychotherapeutics.com </a></p>    
                                <p className="mb-2"> <a href="mailto:strategicpsychotherapeutics@gmail.com" className="custom-footer-link break-word" target="_top">
                                    <i className="fa fa-envelope"></i> strategicpsychotherapeutics@gmail.com</a></p>
                                <p className="mb-2"> <a href="mailto: helpdesk@stratpsych.com" className="custom-footer-link break-word" target="_top">
                                    <i className="fa fa-envelope"></i> Contact Support</a></p>
                                <p className="mb-2"> <a href="https://www.google.co.in/maps/place/Glastonbury+Medical+Arts+Center/@41.7129394,-72.6716982,12z/data=!4m18!1m12!4m11!1m3!2m2!1d-72.6006304!2d41.7135801!1m6!1m2!1s0x89e6519a97414df9:0x520c002631dc9c96!2sGlastonbury+Medical+Arts+Center,+300+Hebron+Ave.+Suite+215,+Glastonbury,+CT+06033!2m2!1d-72.6016583!2d41.7129607!3m4!1s0x89e6519a97414df9:0x520c002631dc9c96!8m2!3d41.7129607!4d-72.6016583" className="custom-footer-link break-word" target="_blank">
                                    <i className="fa fa-map-marker"></i>
                                    Strategic Psychotherapeutics <sup>&reg;</sup>, LLC, Glastonbury Medical Arts Center,
                                    300 Hebron Ave. Suite 215, Glastonbury, CT 06033  </a></p>
                                
                            </div>
                            <div className="col-sm-4 text-left"> 
                            
                                <h5 className="custom-footer-color">RESOURCES</h5>    
                                <p>
                                    <Link to='/approaches' className="custom-footer-link d-block" > Approaches</Link>
                                    <Link to='/techniques' className="custom-footer-link d-block" > Techniques</Link>
                                    <a className="custom-footer-link d-block" href="#" data-toggle="modal" data-target=".bd-example-modal-lg1">psychotherapedia <sup>&reg;</sup></a>
                                    <a className="custom-footer-link d-block" href="https://psychtrends.godaddysites.com/">PsychTrends <sup>&reg;</sup></a>
                                    <a className="custom-footer-link d-block" href="https://psychincubator.com/"> PsychIncubator <sup>&reg;</sup></a>
                                    <a className="custom-footer-link d-block" href="#" data-toggle="modal" data-target=".bd-example-modal-lg4"> StratPsychDx</a>
                                    <a href="https://stratpsychtx.com/"  className="custom-footer-link d-block"> StratPsychTx</a>
                                </p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>



            {/* <div className="row p-4 bg-info text-white">
                <div className="col-sm-4 text-left">
                <h5>ABOUT STRATPSYCH <sup>&reg;</sup></h5> 
                <p>Welcome to StratPsych <sup>&reg;</sup> , the online learning center for mental and behavioral health practitioners.</p>
                </div>
                <div className="col-sm-4 text-left"> 
                
                <h5>CONTACT US</h5>
                    <p className="mb-2"> <a href=" https://www.strategicpsychotherapeutics.com" className="text-light break-word" target="_top"> <i className="fa fa-globe"></i>
                        www.strategicpsychotherapeutics.com </a></p>    
                    <p className="mb-2"> <a href="mailto:strategicpsychotherapeutics@gmail.com" className="text-light break-word" target="_top">
                        <i className="fa fa-envelope"></i> strategicpsychotherapeutics@gmail.com</a></p>
                     <p className="mb-2"> <a href="mailto: helpdesk@stratpsych.com" className="text-light break-word" target="_top">
                        <i className="fa fa-envelope"></i> Contact Support</a></p>
                    <p className="mb-2"> <a href="https://www.google.co.in/maps/place/Glastonbury+Medical+Arts+Center/@41.7129394,-72.6716982,12z/data=!4m18!1m12!4m11!1m3!2m2!1d-72.6006304!2d41.7135801!1m6!1m2!1s0x89e6519a97414df9:0x520c002631dc9c96!2sGlastonbury+Medical+Arts+Center,+300+Hebron+Ave.+Suite+215,+Glastonbury,+CT+06033!2m2!1d-72.6016583!2d41.7129607!3m4!1s0x89e6519a97414df9:0x520c002631dc9c96!8m2!3d41.7129607!4d-72.6016583" className="text-light break-word" target="_blank">
                        <i className="fa fa-map-marker"></i>
                         Strategic Psychotherapeutics <sup>&reg;</sup>, LLC, Glastonbury Medical Arts Center,
                          300 Hebron Ave. Suite 215, Glastonbury, CT 06033  </a></p>
                    
                </div>
                <div className="col-sm-4 text-left"> 
                
                    <h5>RESOURCES</h5>    
                    <p>
                        <Link to='/approaches' className="text-light d-block" > Approaches</Link>
                        <Link to='/techniques' className="text-light d-block" > Techniques</Link>
                        <a className="text-light d-block" href="#" data-toggle="modal" data-target=".bd-example-modal-lg1">Psychotherapedia <sup>&reg;</sup></a>
                        <a className="text-light d-block" href="https://psychtrends.godaddysites.com/">PsychTrends <sup>&reg;</sup></a>
                        <a className="text-light d-block" href="https://psychincubator.com/"> PsychIncubator <sup>&reg;</sup></a>
                        <a className="text-light d-block" href="#" data-toggle="modal" data-target=".bd-example-modal-lg4"> StratPsychDx</a>
                        <a href="https://stratpsychtx.com/"  className="text-light d-block"> StratPsychTx</a>
                    </p>
                </div>
                
            </div> */}

            <div className="row p-4 bg-dark text-white">
                <div className="col-sm-12 text-center p-3">
                <i className="fa fa-copyright"></i> StratPsych <sup>&reg;</sup> . Strategic Psychotherapeutics <sup>&reg;</sup>, LLC, Glastonbury Medical Arts Center,
                        300 Hebron Ave. Suite 215, Glastonbury, CT 06033.
                </div>
            </div>
        </footer>
    );
  }
}