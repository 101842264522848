import React from 'react';
import $ from 'jquery'; 
import { Link } from 'react-router-dom';

const closeModal = () => { // Close the modal using Bootstrap's jQuery 
    const modal = document.querySelector(".bd-example-modal-lg1"); 
    if (modal) { // Hide the modal using Bootstrap's modal function 
        $(modal).modal("hide"); } };
export default class HeaderModal extends React.Component {
    
    render() {
        $('#myModal').on('shown.bs.modal', function () {
            $('#myInput').trigger('focus');
        });
        

        return (
            <div>
                <div className="modal fade bd-example-modal-lg1" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">psychotherapedia <sup>&reg;</sup> </h5>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="py-3 font-weight-bold color-strat"> <span className='color-strat2'>StratPsych's</span> Compendium of the Techniques of Psychotherapy</div>
                                <div className="py-2 font-weight-bold"> <span className='color-cyan'>Introduction</span></div>
                                <div>

                                    It is interesting to note that a definition of a <Link to="/techniques" className="color-strat font-weight-bold" onClick={closeModal}>technique</Link>  of psychotherapy is not mentioned on Wikipedia. When conducting a Google search for psychotherapy technique, it brings one instead to the definition of psychotherapy. While the term <b><Link to="/techniques" className="color-strat font-weight-bold" onClick={closeModal}>technique</Link></b> is mentioned, a concise definition is not offered. Techniques are the smallest units of psychotherapy, which can be depicted by patient-therapist dialogue. These mechanisms of change specify the aim (e.g. Increase emotional experience) and action (e.g. focusing on sensations) by the psychotherapist.
                                </div>

                                <div className="py-2 font-weight-bold">   </div>
                                <div>
                                <img src={require("./../../../assets/images/communication.png")} alt="logo-new.png" className="img-responsive" />
                                </div>

                                <div className="py-2 font-weight-bold">  <span className='color-cyan'>Problem</span> </div>
                                <div>
                                There are over 400 <b><Link to="/approaches" onClick={closeModal}>approaches to psychotherapy</Link></b> (such as cognitive-behavioral, interpersonal, psychodynamic, existential, humanistic etc.), each having a defined set of techniques, and many sharing techniques, but there currently does not exist a compendium of these accessible by clinicians to improve treatment and training, as well as to advance research. One of the problems is that the terms <b><Link to="/techniques" className="color-strat font-weight-bold" onClick={closeModal}>techniques</Link></b>, <b><Link to="/techniques" className="color-strat font-weight-bold" onClick={closeModal}>methods</Link></b>, <b><Link to="/techniques" className="color-strat font-weight-bold" onClick={closeModal}>strategies</Link></b>, and <b><Link to="/approaches" className="color-strat font-weight-bold" onClick={closeModal}>approaches</Link></b> are often used interchangeably.                                
                                </div>

                                <div className="py-2 font-weight-bold">  <span className='color-cyan'>Challenge</span> </div>
                                <div>
                                We have compiled a<b> <Link to="/techniques" onClick={closeModal}>compendium of techniques</Link></b> called <b><Link to="/techniques" onClick={closeModal}>  psychotherapedia<sup>&reg;</sup> </Link></b> organized in a coherent atheoretical framework that allows the user to find these techniques and blend them in the most efficacious manner. This interactive web-based product is easily accessible, and, in the future, we believe will be essential to create scientifically validated treatment protocols based on the individual needs and clinical considerations, as well as the modality of treatment (<b><Link to="/module/48" onClick={closeModal}>individual</Link></b>, <b><Link to="/module/47" onClick={closeModal}>couple</Link></b>, <b><Link to="/module/45" onClick={closeModal}>group</Link></b>, and  <b><Link to="/module/46" onClick={closeModal}>family</Link></b>).
                                </div>

                                <div className="py-2 font-weight-bold">  <span className='color-cyan'>Definitions</span> </div>
                                <div>
                                    <div> As mentioned, there are <i> techniques, methods, strategies,</i> as well as principles of behavioral treatment. </div>
                                    <div className="py-2 pl-4"> <li> <b><Link to="/techniques" className="color-strat font-weight-bold" onClick={closeModal}>Techniques</Link></b> are the smallest units of any type of behavioral and mental health treatment. These units of change specify a certain action on the part of the mental health clinician, such as what Allen Ivey pioneered in the 1960s and which he termed micro-counseling skills. These were the basic techniques of counseling such as reflection of feeling, reflection of content etc. as demonstrated:

                                        <div className="py-1"><b>PT:</b> [welling up of tears]</div>
                                        <div className="py-1"><b>TH:</b> “You seem sad”</div>

                                        This simple interaction is a technique called <b>reflection of feeling.</b> It can be defined and described and demonstrated thru patient-therapist dialogue and then can be modeled as many training programs have done in a variety of formats for decades.</li></div>

                                    <div className="py-2 pl-4">
                                        <li>
                                            <b><Link to="/techniques" className="color-strat font-weight-bold" onClick={closeModal}>Methods</Link> </b>
                                             are groups of related techniques that have a certain therapeutic action. For example, the above demonstrated technique reflection of feeling, is one of many used for restructuring emotions called <b>affective restructuring.</b> Groups of techniques can be organized systematically based on the level of the biopsychosocial system in which the technique has its impact.
                                        </li>
                                    </div>
                                    <div className="py-2 pl-4">
                                        <li>
                                            <b><Link to="/techniques" className="color-strat font-weight-bold" onClick={closeModal}>Strategies</Link> </b>
                                             represent how a clinician organizes a sequence of techniques in the clinical process. Most contemporary forms of psychotherapy specify how techniques can be offered in a systematic way, usually described in that approaches treatment manual. This allows for researchers to deliver a coherent treatment that follows the tenets of each approach.
                                        </li>
                                    </div>
                                    <div className="py-2 pl-4">
                                        <li>
                                            <b><Link to="/approaches" className="color-strat font-weight-bold" onClick={closeModal}>Approaches</Link> </b>
                                            are theoretically-based comprehensive treatment protocols that are typically manualized for training and research. While many approaches share common features some have more distinctive component features.                                        </li>
                                    </div>
                                    <div className="py-2 pl-4">
                                       <i>Please submit techniques that are not listed to our content team for review and inclusion at:</i> <a href="mailto:strategicpsychotherapeutics@gmail.com">strategicpsychotherapeutics@gmail.com</a>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade bd-example-modal-lg2" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">PsychTrends <sup>&reg;</sup></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                PsychTrends <sup>&reg;</sup> explores the intersection of technology, clinical science, and psychotherapeutics. There are a multitude of new developments emerging from research in technology and neuroscience that will be beneficial to clinical work in psychotherapeutics. New theoretical developments and trans-disciplinary collaborations are providing novel frameworks and perspectives for understanding complex phenomena. We offer product reviews, new developments, old technologies with new applications, and technologies that are either experiencing resurgence or where new evidence for efficacy is emerging.
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade bd-example-modal-lg3" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">

                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"> PsychIncubator <sup>&reg;</sup></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                PsychIncubator <sup>&reg;</sup> is a forum that explores new developments in clinical science and psychotherapeutics. All advancements in clinical science and psychotherapeutics have come about because of innovation. Clinicians are all innovators in the sense that, with each patient, it is necessary to compile a unique array of strategies, interventions, and principles to optimize outcome. The process of discovery is what motivates many of us and keeps us open to novel findings. The path that begins with discovering an innovative and promising approach, and ends at an empirical validation of the approach as an effective method, is a long and arduous one. There are many innovators among us who having insight about clinical phenomena and only need to push the envelope in order to develop a novel, more effective or efficient treatment. The experience of clinicians in continual patient encounters is vital to our art and science. Join our experts in learning about some of the innovations and breakthroughs in psychotherapeutics. We will also discuss how to develop and test innovative products and approaches.

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade bd-example-modal-lg4" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">

                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">PsychStrat Dx </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                PsychStrat Dx is a platform for exploring and learning about various aspects of psychodiagnosis and case formulation. Here you can access our team of experts, as well as other clinicians, to discuss issues related to the spectrum of clinical presentations and to receive input from the expert group. While direct consultation on your clinical cases is not permitted due to confidentiality and liability issues, we will discuss considerations and may illustrate examples with published case material.

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade bd-example-modal-lg5" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">

                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"> PsychStrat Tx</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                PsychStrat Tx is our resource for offering treatment strategies for a spectrum of disorders and clinical presentations. Here we offer guides to treatment that are based on unified psychotherapeutics and draw from methodologies from a variety of approaches.
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}