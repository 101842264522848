import React from 'react';
import Slider from 'react-slick';
import { getAllModules } from "./../../actions/modules";
import { connect } from 'react-redux';
import Loader from './../components/loader';
import { toSeoUrl } from './../adminDashboard/components/helper';
import $ from 'jquery'; 

class DashboardMiddle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    UNSAFE_componentWillMount() {
        this.props.dispatch(getAllModules());
    }

    render() {


        let basicCategory = {
            id: 1,
            title: "FOUNDATIONAL",
            categories: this.props.model.modules.filter(x => x.type == "basic").map((_module) => {
                return {
                    id: _module.id,
                    name: _module.name,
                    url: toSeoUrl(String(_module.name + "-" + _module.id))
                };
            })
        }

        let intermediateCategory = {
            id: 2,
            title: "INTERMEDIATE",
            categories: this.props.model.modules.filter(x => x.type == "intermediate").map((_module) => {
                return {
                    id: _module.id,
                    name: _module.name,
                    url: toSeoUrl(String(_module.name + "-" + _module.id))
                };
            })
        }

        let advancedCategory = {
            id: 3,
            title: "ADVANCED",
            categories: this.props.model.modules.filter(x => x.type == "advanced").map((_module) => {
                return {
                    id: _module.id,
                    name: _module.name,
                    url: toSeoUrl(String(_module.name + "-" + _module.id))
                };
            })
        }

        let categories = [basicCategory, intermediateCategory, advancedCategory];

        let settings = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            // the magic
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    dots: true
                }
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    dots: true
                }
            }]
        };

        let model = {};
        model.categories = [];

        categories.forEach(function (category, i) {
            let _title = <li key={i} className="list-group-item border-0 text-dark"> <h4 className="p-3"> {category.title} </h4></li>;
            let _categories = [];
            category.categories.forEach(function (_subcategory, i) {
                _categories.push(
                    <li key={i} className="list-group-item list-group-item-action">
                        <a href={'module/' + _subcategory.id} className="text-secondary" > {_subcategory.name} </a>
                    </li>
                );
            });
            let _category = <div key={i} className="col mb-4">
                <ul className="list-group">
                    {_title}
                    {_categories}
                </ul>
            </div>;

            model.categories.push(_category);

        });

        $(function () {
            $('[data-toggle="popover"]').popover()
        })

        return (<div>
            <div className="container-fluid  text-center text-info bg-white">
                <div className="row">
                    <div className="col-md-4">
                        <div className="h3 py-2 bg-white ">StratPsych <sup>&reg;</sup> Navigator</div>
                        <div className="text-left bg-white text-dark p-3 text-over-flow">
                            <div className="pb-2 text-over-flow"><a href="javascript:;" data-toggle="popover" data-trigger="focus" title="Description" data-content="This is a brief description of the module" data-placement="top" className="no-uderline pb-2 text-over-flow"><strong>Description. </strong> This is a brief description of the module</a></div>
                            <div className="pb-2 text-over-flow"><a href="javascript:;" data-toggle="popover" data-trigger="focus" title="Learning Objectives" data-content="Each module states the objectives you will achieve." data-placement="top" className="no-uderline  pb-2 text-over-flow"><strong>Learning Objectives. </strong>Each module states the objectives you will achieve.</a></div>
                            <div className="pb-2 text-over-flow"><a href="javascript:;" data-toggle="popover" data-trigger="focus" title="Definition" data-content="Each domain includes a brief definition to orient you to the topic." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Definition. </strong>Each domain includes a brief definition to orient you to the topic.</a></div>
                            <div className="pb-2 text-over-flow"><a href="javascript:;" data-toggle="popover" data-trigger="focus" title="Orientation Diagram" data-content="Where it is appropriate we will visually depict inter-relationships among modules to better orient your learning." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Orientation Diagram.</strong>Where it is appropriate we will visually depict inter-relationships among modules to better orient your learning.</a></div>
                            <div className="pb-2 text-over-flow"><a href="javascript:;" data-toggle="popover" data-trigger="focus" title="Module" data-content="This is a summary of the basic information for each domain." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Module. </strong>This is a summary of the basic information for each domain.</a></div>
                            <div className="pb-2 text-over-flow"><a href="javascript:;" data-toggle="popover" data-trigger="focus" title="Convergence" data-content="This summarizes the commonalities that various approaches and research suggest are important." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Convergence. </strong>This summarizes the commonalities that various approaches and research suggest are important.</a></div>
                            <div className="pb-2 text-over-flow"><a href="javascript:;" data-toggle="popover" data-trigger="focus" title="Therapeutic Goals" data-content="This presents the basic general goals derived from various approaches relating to the specific domain." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Therapeutic Goals. </strong>This presents the basic general goals derived from various approaches relating to the specific domain.</a></div>
                            <div className="pb-2 text-over-flow"><a href="javascript:;" data-toggle="popover" data-trigger="focus" title="Therapeutic Tasks" data-content="This refers to specific therapeutic tasks that you should try to achieve in your work." data-placement="top" className="no-uderline text-over-flow  pb-2"><strong>Therapeutic Tasks. </strong>This refers to specific therapeutic tasks that you should try to achieve in your work.</a></div>
                            <div className="pb-2 text-over-flow"><a href="javascript:;" data-toggle="popover" data-trigger="focus" title="Principles" data-content="This is a summary of the essential principles of working in the domain." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Principles. </strong>This is a summary of the essential principles of working in the domain.</a></div>
                            <div className="pb-2 text-over-flow"><a href="javascript:;" data-toggle="popover" data-trigger="focus" title="Basic Skills" data-content="Here we highlight the basic skills for you to master and give you some suggestions for practicing them." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Basic Skills.</strong>Here we highlight the basic skills for you to master and give you some suggestions for practicing them.</a></div>
                            <div className="pb-2 text-over-flow"><a href="javascript:;" data-toggle="popover" data-trigger="focus" title="Pattern Recognition Tools" data-content="These are helpful guides that visually depict processes and component parts of domains, as well as inter-relationships necessary for understanding the domains." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Pattern Recognition Tools. </strong>These are helpful guides that visually depict processes and component parts of domains, as well as inter-relationships necessary for understanding the domains.</a></div>
                            <div className="pb-2 text-over-flow"><a href="javascript:;" data-toggle="popover" data-trigger="focus" title="Heuristics—Clinical Rules of Thumb" data-content="There are certain decision making rules that develop from extensive clinical experience." data-placement="top" className="no-uderline text-over-flow  pb-2"><strong>Heuristics—Clinical Rules ofThumb.</strong>There are certain decision making rules that develop from extensive clinical experience. </a></div>
                            <div className="pb-2 text-over-flow"><a href="javascript:;" data-toggle="popover" data-trigger="focus" title="psychotherapedia" data-content="We will select a group of techniques from this compendium that are helpful to consider when working in this domain." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>psychotherapedia<sup>&reg;</sup>.</strong>We will select a group of techniques from this compendium that are helpful to consider when working in this domain.</a></div>
                            <div className="pb-2 text-over-flow"><a href="javascript:;" data-toggle="popover" data-trigger="focus" title="Approaches" data-content=" We offer a list of approaches that are useful in the domain and generally offer training for those interested." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Approaches. </strong> We offer a list of approaches that are useful in the domain and generally offer training for those interested.</a></div>
                            <div className="pb-2 text-over-flow"><a href="javascript:;" data-toggle="popover" data-trigger="focus" title="Module Post-Test" data-content="Each module ends with a post-test that you take to receive CEs and confirm you have completed the objectives." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Module Post-Test. </strong>Each module ends with a post-test that you take to receive CEs and confirm you have completed the objectives</a></div>

                        </div>
                    </div>
                    <div className="col-md-8 border border-hr border-top-0 border-right-0 border-bottom-0 border-hide-mobile border-mobile-top">
                        {/*<div className="h3 py-3 bg-white">History</div>*/}
                        <div className="text-left bg-white text-dark p-3">
                            <p>
                                {/*<strong>The Mission</strong><br/>*/}
                                Welcome to <strong>StratPsych <sup>&reg;</sup></strong> ,the online learning center for mental and behavioral health practitioners. The goal of StratPsych <sup>&reg;</sup> is to help you become the most effective clinician possible, guiding you on a path of continuous improvement to reach your highest performance goals. By maximizing your expertise,technical skills, and knowledge you will increase patient satisfaction and optimize your successful outcome rates.</p>
                            <br />
                            <p><strong>The Process</strong><br />Psychotherapeutic proficiency evolves through the mastery of increasingly complex material.<strong> StratPsych <sup>&reg;</sup></strong> consumers begin their training with the basics and proceed through the topic domains common to all psychotherapeutics as dictated by their goals. </p>
                            <p><strong>The Training</strong><br />The <strong> StratPsych <sup>&reg;</sup></strong> team of experts will help you focus on training goals in order to distill what is most useful from a multitude of sources and provide you with the essential information that you need to be effective with the patients you serve. Psychotherapeutic effectiveness begins with basic modules that represent the fundamental domains of all psychotherapeutics and are the building blocks of your knowledge base. Within each domain we provide links to webinars and additional training options. Expert consultation will help guide your training so that you can utilize your time and resources optimally </p>
                            <p><strong>Who Can Benefit?</strong><br />Our consumers include mental and behavioral health practitioners including Licensed Professional Counselors (LPC), Licensed Clinical Social Workers (LSCW), Licensed Marriage and Family Therapists, Advanced Practice Nurses (APRN), Licensed Psychologists, and Psychiatrists. From the domain modules to the advanced level of developing treatment packages for individualized care, <strong> StratPsych <sup>&reg;</sup></strong> will target the training that you seek.</p>
                        </div>
                    </div>

                </div>
            </div>
            <hr className="hr-color" />
            <div className="container-fluid py-3 text-center h3 text-info">
                Select the Domain
                        </div>

            <hr className="hr-color" />
            <div className="container-fluid text-center text-white pb-5">
                <div>
                    <Slider {...settings}>
                     {this.props.model.modules.length > 0 && model.categories}
                     {this.props.model.modules.length == 0 && <Loader />}
                    </Slider>
                </div>
            </div>
        </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        model: state.store.module
    };
}

export default connect(mapStateToProps)(DashboardMiddle);